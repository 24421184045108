import { checkHidingRules } from "support/helpers";

export function optionalRules(fieldValues, items) {
  let required = {};

  function processItem(item) {
    const fieldShown = checkHidingRules(item.hiding_rules, fieldValues);

    if (item.optional_rules && fieldShown) {
      let rules = {};

      item.optional_rules.forEach((rule) => {
        rules[rule.id] =
          (fieldValues[rule.id] || 0) === rule.value &&
          !fieldValues[item.id] &&
          fieldValues[item.id] !== 0;
      });

      required[item.id] = Object.values(rules).every(Boolean);
    }

    if (item.items) {
      item.items.forEach((subItem) => {
        processItem(subItem);
      });
    }

    if (item.dropdowns) {
      item.dropdowns.forEach((subItem) => {
        processItem(subItem);
      });
    }
  }

  items.forEach((item) => {
    processItem(item);
  });

  return required;
}

export function determineDependantFields(items) {
  const dependantFields = {};

  function processItem(item) {
    if (item.optional_rules) {
      dependantFields[item.id] = item.optional_rules.map(({ id }) => id);
    }

    if (item.items) {
      item.items.forEach((subItem) => {
        processItem(subItem);
      });
    }
  }

  items.forEach((item) => {
    processItem(item);
  });

  return dependantFields;
}
